import React from "react";
import Avatar from "./Avatar.jsx";
import Detail from "./Detail.jsx";

function Card(props) {
  return (
    <div className="card">
      <div className="top">
        <p className="number">{props.number}</p>
        <h2 className="name">{props.name}</h2>
        <Avatar img={props.img} />
      </div>
      <div className="bottom">
        <Detail detailInfo={props.tel}/>
        <p className="email">{props.email}</p>
        <p className="DOB">{props.DOB}</p>
      </div>
    </div>
  );
}

export default Card;