import React from "react";
import blackRibbon from "../components/Images/black-ribbon.png";

function Detail(props) {
  if (props.detailInfo === "defunct") {
    return (
      <img className="blackRibbon-img"
      src={blackRibbon} alt="Black Ribbon" width="60" height="80"/>
    );
  } else {
    return <p className="info">{props.detailInfo}</p>;
  }
}

export default Detail;
